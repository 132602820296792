const compose =
  <T, U, V>(f: (x: U) => V, g: (y: T) => U) =>
  (x: T): V =>
    f(g(x));

export const float = (val: string | number): number => {
  if (typeof val === 'string') {
    const parsedValue = parseFloat(val);
    if (isNaN(parsedValue)) {
      throw new Error('Failed to parse float');
    }
    return parsedValue;
  }
  return Number(val); // If it's already a number, just return it
};

const removeCommas = (val: string): string => val.replace(/,/g, '');

export const fixed2 = <T extends string | number>(val: T): string => {
  return removeCommas(
    val.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'decimal',
    }),
  );
};

export const fixed3 = <T extends string | number>(val: T): string => {
  return removeCommas(
    val.toLocaleString('en-US', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
      style: 'decimal',
    }),
  );
};

export const toFloat = compose(fixed2, float);

export const sum = (...values: (string | number)[]): string => {
  const total = values.reduce((acc, val) => float(acc) + float(val), 0); // Initialize accumulator as a number
  return fixed2(total);
};

export const subtract = (
  val1: string | number,
  val2: string | number,
  val3: string | number = '0.00',
  val4: string | number = '0.00',
): string => fixed2(float(val1) - float(val2) - float(val3) - float(val4));

export const getPercentageOfValue = <T extends string | number>(
  value: T,
  percentage: T,
): string => {
  return fixed2((float(value) * float(percentage)) / 100);
};

// Function to round to the nearest cent
export const roundToNearestCent = (amount: string): string => {
  const parsedAmount = parseFloat(amount);
  return (Math.round(parsedAmount * 100) / 100).toFixed(2);
};
