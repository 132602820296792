import moment from "moment-timezone";

export const findOpenSlot = (timetableData, timezone) => {
    const currentTime = moment().tz(timezone); // Get current time in the specified timezone
    const currentDay = currentTime.day(); // 0 for Sunday, 1 for Monday, etc.
    const currentHour = currentTime.hour();
    const currentMinute = currentTime.minute();
    
    // Find timetable data for the current day
    const currentDayData = timetableData.find(data => data.dayId === currentDay && data?.status);

    if (!currentDayData) {
        return null; // No timetable data available for the current day
    }

    // Find the open slot for the current time
    for (const slot of currentDayData.slots) {
        const slotStartHour = parseInt(slot.startTime.split(':')[0]);
        const slotStartMinute = parseInt(slot.startTime.split(':')[1]);
        const slotEndHour = parseInt(slot.endTime.split(':')[0]);
        const slotEndMinute = parseInt(slot.endTime.split(':')[1]);

        // Check if current time is within slot's start and end time
        if (
            currentHour > slotStartHour ||
            (currentHour === slotStartHour && currentMinute >= slotStartMinute)
        ) {
            if (
                currentHour < slotEndHour ||
                (currentHour === slotEndHour && currentMinute <= slotEndMinute)
            ) {
                return slot; // Slot is open
            }
        }
    }

    return null; // No open slot found for the current time
}