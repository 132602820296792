/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import {
  IAddon,
  IItem,
  IOffer,
  IOrderDetail,
  IOrderModel,
  ITaxItem,
  initializeItemBillingDetails,
  initializeOrderBillingDetails,
  initializeOrderData,
} from "@/models/order.model";
import React, {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { fixed3, sum } from "@/utils/numberUtils";
import { FeeModel } from "@/models/fee.model";
import {
  ADD_ITEM,
  APPLY_COUPON,
  DECREMENT_ITEM_COUNT,
  DECREMENT_ITEM_COUNT_BY_ID,
  INCREMENT_ITEM_COUNT,
  INCREMENT_ITEM_COUNT_BY_ID,
  INIT_ORDER_DATA,
  SET_RECEIPT_TICKET_NUMBER,
} from "./actionTypes";
import {
  AddItemAction,
  ApplyCouponAction,
  DecrementItemCountAction,
  DecrementItemCountByIdAction,
  IncrementItemCountAction,
  IncrementItemCountByIdAction,
  InitOrderDataAction,
  SetReceiptTicketNumberAction,
} from "./actions";
import { MetadataContext, MetadataStateModel } from "./metadata.context";
import { ItemModel } from "@/models/item.model";
import { TaxItemModel } from "@/models/taxItem.model";
import { LocalStorageService } from "@/services/localStorage.service";

export const RESET_ORDER_DATA = "[ORDER CONTEXT] Reset Order Data";
export const initOrderData = (order: IOrderModel): InitOrderDataAction => ({
  type: INIT_ORDER_DATA,
  payload: { order },
});

export const addItem = (item: IItem): AddItemAction => ({
  type: ADD_ITEM,
  payload: { item },
});

export const incrementItemCount = (
  itemId: string
): IncrementItemCountAction => ({
  type: INCREMENT_ITEM_COUNT,
  payload: { itemId },
});

export const incrementItemCountById = (
  itemId: string
): IncrementItemCountByIdAction => ({
  type: INCREMENT_ITEM_COUNT_BY_ID,
  payload: { itemId },
});

export const decrementItemCount = (
  itemId: string
): DecrementItemCountAction => ({
  type: DECREMENT_ITEM_COUNT,
  payload: { itemId },
});

export const decrementItemCountById = (
  itemId: string
): DecrementItemCountByIdAction => ({
  type: DECREMENT_ITEM_COUNT_BY_ID,
  payload: { itemId },
});

export const setReceiptTicketNumber = (
  receiptNumber: string,
  ticketNumber: string
): SetReceiptTicketNumberAction => ({
  type: SET_RECEIPT_TICKET_NUMBER,
  payload: { receiptNumber, ticketNumber },
});

export const applyCoupon = (offers: IOffer[]): ApplyCouponAction => ({
  type: APPLY_COUPON,
  payload: offers,
});

export type ActionType = {
  type: string;
  payload: any;
};

export interface OrderStateModel {
  order: IOrderModel;
}

const initialState: OrderStateModel = {
  order: initializeOrderData(),
};

const reducer =
  (metadata: MetadataStateModel) =>
  (state: OrderStateModel, action: ActionType) => {
    switch (action.type) {
      case INIT_ORDER_DATA:
        return {
          ...state,
          order: action.payload.order,
        };
      case RESET_ORDER_DATA:
        LocalStorageService.setCartData(initializeOrderData());
        return {
          ...state,
          order: initializeOrderData(),
        };
      case APPLY_COUPON:
        const applyCouponOrderData = structuredClone(state.order);
        applyCouponOrderData.orders[0].offers = action.payload;
        calculateTotals(applyCouponOrderData, metadata);
        return {
          ...state,
          order: applyCouponOrderData,
        };
      case SET_RECEIPT_TICKET_NUMBER:
        const orderData = structuredClone(state.order);
        orderData.receiptNumber = action.payload.receiptNumber;
        orderData.ticketNumber = action.payload.ticketNumber;
        LocalStorageService.setCartData(orderData);
        return {
          ...state,
          order: orderData,
        };
      case ADD_ITEM:
        const addItemOrderData = structuredClone(state.order);
        addItemOrderData.orders[0].items.push(action.payload.item);
        return {
          ...state,
          order: calculateTotals(addItemOrderData, metadata),
        };
      case INCREMENT_ITEM_COUNT_BY_ID:
        const incrementByIdOrderData = structuredClone(state.order);
        const incrementByIdItemIndex =
          incrementByIdOrderData.orders[0].items.findIndex(
            (item) => item.id == action.payload.itemId
          );
        if (incrementByIdItemIndex > -1) {
          incrementByIdOrderData.orders[0].items[
            incrementByIdItemIndex
          ].quantity += 1;
        }

        return {
          ...state,
          order: calculateTotals(incrementByIdOrderData, metadata),
        };
      case DECREMENT_ITEM_COUNT_BY_ID:
        const decrementByIdOrderData = structuredClone(state.order);
        const decrementByIdItemIndex =
          decrementByIdOrderData.orders[0].items.findIndex(
            (item) => item.id == action.payload.itemId
          );
        if (decrementByIdItemIndex > -1) {
          if (
            decrementByIdOrderData.orders[0].items[decrementByIdItemIndex]
              .quantity === 1
          ) {
            decrementByIdOrderData.orders[0].items.splice(
              decrementByIdItemIndex,
              1
            );
          } else {
            decrementByIdOrderData.orders[0].items[
              decrementByIdItemIndex
            ].quantity -= 1;
          }
          return {
            ...state,
            order: calculateTotals(decrementByIdOrderData, metadata),
          };
        }
      case INCREMENT_ITEM_COUNT:
        const incrementOrderData = structuredClone(state.order);
        const incrementItemIndex = incrementOrderData.orders[0].items.findIndex(
          (item) => item.itemId == action.payload.itemId
        );
        if (incrementItemIndex > -1) {
          incrementOrderData.orders[0].items[incrementItemIndex].quantity += 1;
        }

        return {
          ...state,
          order: calculateTotals(incrementOrderData, metadata),
        };
      case DECREMENT_ITEM_COUNT:
        const decrementOrderData = structuredClone(state.order);
        const decrementItemIndex = decrementOrderData.orders[0].items.findIndex(
          (item) => item.itemId == action.payload.itemId
        );
        if (decrementItemIndex > -1) {
          if (
            decrementOrderData.orders[0].items[decrementItemIndex].quantity ===
            1
          ) {
            decrementOrderData.orders[0].items.splice(decrementItemIndex, 1);
          } else {
            decrementOrderData.orders[0].items[
              decrementItemIndex
            ].quantity -= 1;
          }
        }
        return {
          ...state,
          order: calculateTotals(decrementOrderData, metadata),
        };
      default:
        return state;
    }
  };

const calculateTotals = (
  orderData: IOrderModel,
  metadata: MetadataStateModel
): IOrderModel => {
  orderData.billingDetails = initializeOrderBillingDetails();
  for (let i = 0; i < orderData.orders.length; i++) {
    const guestOrder: IOrderDetail = orderData.orders[i];
    guestOrder.billingDetails = initializeOrderBillingDetails();

    // Reset Offer Amounts
    guestOrder.offers.forEach((offer) => {
      offer.amount = "0.00";
    });

    for (var j = 0; j < guestOrder.items.length; j++) {
      const orderItem: IItem = guestOrder.items[j];
      orderItem.billingDetails = initializeItemBillingDetails();

      let itemData: ItemModel | undefined = metadata.items.find(
        (element) => element._id == orderItem.itemId
      );

      if (itemData) {
        // Calculate addons total
        const addonsTotal: number = calculateAddonsTotal(orderItem);

        // Calculate item total
        const itemTotal: number = calculateItemTotal(
          orderItem,
          addonsTotal,
          guestOrder,
          itemData
        );

        // Calculate item tax
        const itemTax: number = calculateItemTax(
          orderItem,
          itemData,
          guestOrder,
          itemTotal,
          metadata
        );

        // Calculate item tax groups
        const itemTaxGroup: number = calculateItemTaxGroups(
          orderItem,
          itemData,
          guestOrder,
          itemTotal,
          metadata
        );

        orderItem.billingDetails.tax = sum((itemTax + itemTaxGroup).toString());

        // Guest grand total
        const grandTotal: number = itemTotal + +orderItem.billingDetails.tax;
        orderItem.billingDetails.grandTotal = sum(grandTotal.toString());
        orderItem.billingDetails.netAmount =
          orderItem.billingDetails.grandTotal;
      }
    }

    // Calculate fee
    const guestFee: number = calculateFee(
      guestOrder,
      orderData.guestCount,
      orderData.orderByGuestEnabled,
      metadata
    );
    guestOrder.billingDetails.fee = sum(guestFee.toString());

    guestOrder.billingDetails.grandTotal = sum(
      (
        +guestOrder.billingDetails.total +
        +guestOrder.billingDetails.tax +
        +guestOrder.billingDetails.fee +
        +guestOrder.billingDetails.tip -
        +guestOrder.billingDetails.discount
      ).toString()
    );

    guestOrder.billingDetails.grandTotalWithTips =
      guestOrder.billingDetails.grandTotal;
    guestOrder.billingDetails.netAmount = guestOrder.billingDetails.grandTotal;

    // Billing at order level
    orderData.billingDetails.total = sum(
      (
        +orderData.billingDetails.total + +guestOrder.billingDetails.total
      ).toString()
    );

    orderData.billingDetails.tax = sum(
      (
        +orderData.billingDetails.tax + +guestOrder.billingDetails.tax
      ).toString()
    );

    orderData.billingDetails.fee = sum(
      (
        +orderData.billingDetails.fee + +guestOrder.billingDetails.fee
      ).toString()
    );

    orderData.billingDetails.discount = sum(
      (
        +orderData.billingDetails.discount + +guestOrder.billingDetails.discount
      ).toString()
    );

    orderData.billingDetails.tip = sum(
      (
        +orderData.billingDetails.tip + +guestOrder.billingDetails.tip
      ).toString()
    );

    orderData.billingDetails.grandTotal = sum(
      (
        +orderData.billingDetails.grandTotal +
        +guestOrder.billingDetails.grandTotal
      ).toString()
    );

    orderData.billingDetails.grandTotalWithTips = sum(
      (
        +orderData.billingDetails.grandTotalWithTips +
        +guestOrder.billingDetails.grandTotalWithTips
      ).toString()
    );
    orderData.billingDetails.netAmount =
      orderData.billingDetails.grandTotalWithTips;
  }
  LocalStorageService.setCartData(orderData);

  return orderData;
};

const calculateAddonsTotal = (orderItem: IItem): number => {
  let addonsTotal = 0;
  for (var i = 0; i < orderItem.addons.length; i++) {
    let addon: IAddon = orderItem.addons[i];
    addonsTotal += +addon.total;
  }
  orderItem.addonsTotal = sum(addonsTotal);
  return addonsTotal;
};

const getDiscountItemAmount = (
  itemAmount: number,
  offers: IOffer[],
  itemData: ItemModel
): {
  discountedItemAmount: string;
  totalDiscount: string;
} => {
  let discountedItemAmount: number = itemAmount;
  let totalDiscount: number = 0.0;

  offers.forEach((offer) => {
    let discountAmount: number = (itemAmount * +offer.percentage) / 100;

    if (!itemData.settings.oosExcludeFromDiscounts) {
      totalDiscount = totalDiscount + discountAmount;
    }
    discountedItemAmount = discountedItemAmount - discountAmount;

    offer.amount = sum(+offer.amount + discountAmount);
  });

  return {
    discountedItemAmount: sum(discountedItemAmount),
    totalDiscount: sum(totalDiscount),
  };
};

const calculateItemTotal = (
  orderItem: IItem,
  addonsTotal: number,
  guestOrder: IOrderDetail,
  itemData: ItemModel
): number => {
  let itemTotal: number = orderItem.quantity * +orderItem.price + addonsTotal;

  const discountedAmounts: {
    discountedItemAmount: string;
    totalDiscount: string;
  } = getDiscountItemAmount(itemTotal, guestOrder.offers, itemData);

  let discount: string =
    guestOrder.offers.length === 0 ? "0.00" : discountedAmounts.totalDiscount;
  let discountedAmount: string =
    guestOrder.offers.length === 0
      ? sum(itemTotal)
      : discountedAmounts.discountedItemAmount;

  orderItem.billingDetails.subtotal = sum(itemTotal);
  orderItem.billingDetails.discount = discount;
  orderItem.billingDetails.total = discountedAmount;
  guestOrder.billingDetails.total = sum(
    +guestOrder.billingDetails.total,
    itemTotal
  );
  guestOrder.billingDetails.discount = sum(
    +guestOrder.billingDetails.discount,
    +discount
  );
  return +discountedAmount;
};

const calculateFee = (
  guestOrder: IOrderDetail,
  guestCount: number,
  orderByGuestEnabled: boolean,
  metadata: MetadataStateModel
): number => {
  let guestFee = 0.0;
  guestOrder.fees = [];

  const fees: FeeModel[] = metadata.fees.filter(
    (element) => !element.isDiscretionary
  );

  const feesToApply: FeeModel[] = [];

  for (var i = 0; i < fees.length; i++) {
    var element = fees[i];
    if (element.isEveryOrder) {
      feesToApply.push(element);
    } else if (guestCount >= element.guests) {
      feesToApply.push(element);
    }
  }

  for (var i = 0; i < feesToApply.length; i++) {
    let fee = feesToApply[i];

    if (fee.isPercentage) {
      let feeToApply: number =
        (+guestOrder.billingDetails.total * +fee.fee) / 100;

      guestFee += feeToApply;
      guestOrder.fees.push({
        feeId: fee._id,
        name: fee.name,
        fee: fee.fee,
        isPercentage: fee.isPercentage,
        isPaidToEmployees: fee.isPaidToEmployees,
        amount: sum(feeToApply),
        netAmount: sum(feeToApply),
        refundAmount: "0.00",
      });
    } else {
      let feeToApply: number = orderByGuestEnabled
        ? +fee.fee / guestCount
        : +fee.fee;

      guestFee += feeToApply;
      guestOrder.fees.push({
        feeId: fee._id,
        name: fee.name,
        fee: fee.fee,
        isPercentage: fee.isPercentage,
        isPaidToEmployees: fee.isPaidToEmployees,
        amount: sum(feeToApply),
        netAmount: sum(feeToApply),
        refundAmount: "0.00",
      });
    }
  }

  return guestFee;
};

const calculateItemTax = (
  orderItem: IItem,
  itemData: ItemModel,
  guestOrder: IOrderDetail,
  itemTotal: number,
  metadata: MetadataStateModel
) => {
  let itemTax = 0.0;
  orderItem.taxItems = [];
  for (var i = 0; i < itemData.taxItems.length; i++) {
    var taxItemId = itemData.taxItems[i];
    var taxItem: TaxItemModel | undefined = metadata.taxItems.find(
      (element) => element._id == taxItemId
    );

    const taxExemptions = metadata.taxExemptions.filter((taxExemption) =>
      taxExemption.taxItems.includes(taxItemId)
    );

    if (taxItem) {
      if (
        taxItem.percentage != 0.0 &&
        taxItem.percentage != 0.0 &&
        taxExemptions.length === 0
      ) {
        let taxAmount = (itemTotal * taxItem.percentage) / 100;
        guestOrder.billingDetails.tax = sum(
          guestOrder.billingDetails.tax,
          taxAmount
        );
        itemTax += taxAmount;
        orderItem.taxItems.push({
          taxItemId: taxItemId,
          percentage: fixed3(taxItem.percentage.toString()),
          name: taxItem.name,
          amount: sum(taxAmount),
          netAmount: sum(taxAmount),
          refundAmount: "0.00",
        });
      }
    }
  }
  return itemTax;
};

const calculateItemTaxGroups = (
  orderItem: IItem,
  itemData: ItemModel,
  guestOrder: IOrderDetail,
  itemTotal: number,
  metadata: MetadataStateModel
) => {
  let itemTax = 0.0;
  orderItem.taxGroups = [];
  for (var i = 0; i < itemData.taxGroups.length; i++) {
    var taxGroupId = itemData.taxGroups[i];
    var taxGroup = metadata.taxGroups.find(
      (element) => element._id == taxGroupId
    );

    if (taxGroup) {
      let percentage = 0.0;
      let totalTaxAmount = 0.0;
      const taxItems: ITaxItem[] = [];

      for (var i = 0; i < taxGroup.taxItems.length; i++) {
        var taxItemId = taxGroup.taxItems[i];
        var taxItem = metadata.taxItems.find(
          (element) => element._id == taxItemId
        );
        if (taxItem) {
          const taxExemptions = metadata.taxExemptions.filter((taxExemption) =>
            taxExemption.taxItems.includes(taxItemId)
          );

          if (taxExemptions.length === 0 && taxItem.percentage != 0.0) {
            percentage += taxItem.percentage;
            let taxAmount = (itemTotal * taxItem.percentage) / 100;

            orderItem.taxItems.push({
              taxItemId: taxItemId,
              percentage: fixed3(taxItem.percentage),
              name: taxItem.name,
              amount: sum(taxAmount.toString()),
              netAmount: sum(taxAmount.toString()),
              refundAmount: "0.00",
            });

            taxItems.push({
              taxItemId: taxItemId,
              percentage: fixed3(taxItem.percentage),
              name: taxItem.name,
              amount: sum(taxAmount),
              netAmount: sum(taxAmount),
              refundAmount: "0.00",
            });

            guestOrder.billingDetails.tax = sum(
              guestOrder.billingDetails.tax,
              taxAmount
            );
            itemTax += taxAmount;
            totalTaxAmount += taxAmount;
          }
        }
      }

      orderItem.taxGroups.push({
        taxGroupId: taxGroupId,
        percentage: fixed3(percentage),
        name: taxGroup.name,
        amount: fixed3(totalTaxAmount),
        taxItems: taxItems,
      });
    }
  }
  return itemTax;
};

export const OrderContext = createContext<{
  state: any;
  dispatch: Dispatch<ActionType>;
}>({ state: initialState, dispatch: () => null });

export const OrderContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { state: metadataState } = useContext(MetadataContext);
  const [state, dispatch] = useReducer(reducer(metadataState), initialState);

  useEffect(() => {
    const orderData = LocalStorageService.getCartData();
    if (orderData) {
      dispatch(initOrderData(orderData));
    }
  }, []);

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      {children}
    </OrderContext.Provider>
  );
};
