import { OrderTypeEnum } from '@/enum/order.enum';
import { PaymentModeEnum, PaymentTypeEnum } from '@/enum/payment.enum';
import { v4 as uuidv4 } from "uuid";
import * as moment from "moment";

export interface IRefundRequestProp {
    id: string;
    name: string;
    amount: string;
}

export interface ITogoGuest {
    guestId: string | null;
    name: string;
    contactNumber: string;
    address: string;
    specialInstructions: string;
    isOrderReadyToPickup: boolean;
}

export interface IFees {
    feeId: string;
    name: string;
    fee: string;
    isPercentage: boolean;
    amount: string;
    refundAmount: string;
    netAmount: string;
    isPaidToEmployees: boolean;
}

export interface ITaxItem {
    taxItemId: string;
    percentage: string;
    name: string;
    amount: string;
    refundAmount: string;
    netAmount: string;
}

export interface ITaxGroup {
    taxGroupId: string;
    percentage: string;
    name: string;
    amount: string;
    taxItems: ITaxItem[];
}

export interface IBillingDetails {
    total: string; // only item totals
    tax: string;
    fee: string;
    discount: string;
    tip: string;
    grandTotal: string; //> total + tax + fee - discount
    grandTotalWithTips: string;
    refundAmount: string;
    refundFeeAmount: string;
    refundTipAmount: string;
    refundTaxAmount: string;
    refundAmountAndTax: string;
    netAmount: string; //> grandTotal - refund
}

export interface IItemBillingDetails
    extends Omit<
        IBillingDetails,
        'fee' | 'tip' | 'grandTotalWithTips' | 'refundTipAmount' | 'refundFeeAmount'
    > {
    subtotal: string;
}

export interface IRefundItemData {
    id: string; // id from items[] id
    name: string;
    amount: string;
    taxAmount: string;
    netAmount: string; // amount + taxAmount
    taxItems: IRefundTaxItemData[];
}

export interface IRefundTaxItemData {
    taxId: string;
    name: string;
    amount: string;
}

export interface ICardDetails {
    amount: string;
    tip: string;
    refundAmountAndTip: string; // refundAmount + refundTip
    paymentDetailId: string;
    total: string; // amount + tip
    refundAmount: string;
    refundTip: string;
    refundFee: string;
    netAmount: string; // total - refundAmountAndTip
    cardLast4Digits: string;
    paymentToken: string;
    isTipsAdjusted: boolean;
    paymentGateway: string;
    isVirtualPay: boolean;
}

export interface ICashDetails {
    cashRegisterId: string;
    cashRegisterShiftId: string;
    paymentDetailId: string;
    cashTendered: string;
    cashReturned: string;
    amount: string;
    tip: string;
    total: string; // amount + tip
    refundAmount: string;
    refundTip: string;
    refundFee: string;
    refundAmountAndTip: string; // refundAmount + refundTip
    netAmount: string; // total - refundAmountAndTip
    isTipsAdjusted: boolean;
}

export interface IGiftCardDetails {
    giftCardId: string;
    cashRegisterId: string;
    cashRegisterShiftId: string;
    paymentDetailId: string;
    approvedBy: string;
    amount: string;
    tip: string;
    total: string; // amount + tip
    refundAmount: string;
    refundTip: string;
    refundFee: string;
    refundAmountAndTip: string; // refundAmount + refundTip
    netAmount: string; // total - refundAmountAndTip
    isTipsAdjusted: boolean;
}

export interface IItem {
    id: string;
    itemId: string;
    categoryId: string;
    categoryName: string;
    name: string;
    itemName: string;
    notes: string;
    price: string;
    quantity: number;
    taxItems: ITaxItem[];
    taxGroups: ITaxGroup[];
    addons: IAddon[];
    addonsTotal: string;
    sentToKitchenQuantity: number;
    billingDetails: IItemBillingDetails;
    isPricedByWeight: boolean;
    weight: string;
}

export interface IPayments {
    paymentId: string;
    paymentType: PaymentTypeEnum;
    paymentMode: PaymentModeEnum;
    billingDetails: IBillingDetails;
    isPaid: boolean;
    cardDetails: ICardDetails[];
    cashDetails: ICashDetails[];
    giftCardDetails: IGiftCardDetails[];
    splitPayConfig: [];
}

export interface IAddon {
    addonId: string;
    addonGroupId: string;
    variablePriceId: string;
    name: string;
    addonName: string;
    price: string;
    quantity: number;
    subtotal: string;
    discount: string;
    total: string;
}

export interface IOffer {
    offerId: string;
    name: string;
    code: string;
    percentage: string;
    amount: string;
}

export interface IOrderDetail {
    orderDetailId: string;
    items: IItem[];
    voidItems: IItem[];
    fees: IFees[];
    billingDetails: IBillingDetails;
    payments: IPayments[];
    offers: IOffer[];
}

export interface IOrderModel {
    orderId: string;
    orderByGuestEnabled: boolean;
    guestCount: number;
    orderType: OrderTypeEnum;
    createdAt: string;
    locationId: string;
    receiptNumber: string;
    ticketNumber: string;
    isCompleted: boolean;
    isPaid: boolean;
    employeeId: string | null;
    employeeName: string;
    notes: string;
    billingDetails: IBillingDetails;
    voidReason: string;
    source: string;
    paymentOption: string;
    orders: IOrderDetail[];
    errorRecord: boolean;
    tableId: string | null;
    tableName: string;
    togoGuest: ITogoGuest;
    refunds: [];
}

export const initializeOrderData = () => {
    const orderData: IOrderModel = {
        orderId: uuidv4(),
        orderByGuestEnabled: false,
        guestCount: 1,
        orderType: OrderTypeEnum.ONLINE,
        createdAt: moment.utc().format(),
        orders: [
            {
                orderDetailId: uuidv4(),
                voidItems: [],
                items: [],
                fees: [],
                offers: [],
                payments: [],
                billingDetails: initializeOrderBillingDetails(),
            },
        ],
        locationId: "",
        tableId: null,
        tableName: "",
        receiptNumber: "",
        ticketNumber: "",
        isCompleted: false,
        isPaid: false,
        employeeId: null,
        employeeName: "",
        notes: "",
        billingDetails: initializeOrderBillingDetails(),
        voidReason: "",
        source: "online ordering",
        paymentOption: "",
        togoGuest: {
            address: "",
            contactNumber: "",
            guestId: null,
            isOrderReadyToPickup: false,
            name: "",
            specialInstructions: "",
        },
        errorRecord: false,
        refunds: [],
    };
    return orderData;
};

export const initializeOrderBillingDetails = (): IBillingDetails => {
    return {
        total: "0.00",
        tax: "0.00",
        fee: "0.00",
        tip: "0.00",
        discount: "0.00",
        grandTotal: "0.00",
        grandTotalWithTips: "0.00",
        netAmount: "0.00",
        refundAmount: "0.00",
        refundTaxAmount: "0.00",
        refundAmountAndTax: "0.00",
        refundFeeAmount: "0.00",
        refundTipAmount: "0.00",
    };
};

export const initializeItemBillingDetails = (): IItemBillingDetails => {
    return {
        subtotal: "0.00",
        tax: "0.00",
        total: "0.00",
        discount: "0.00",
        grandTotal: "0.00",
        netAmount: "0.00",
        refundAmount: "0.00",
        refundTaxAmount: "0.00",
        refundAmountAndTax: "0.00",
    };
};