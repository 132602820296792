import { jwtDecode } from "jwt-decode";
import { EncryptDecryptService } from "./encryptDecrypt.service";

export class LocalStorageService {
    // Cart Data
    static setCartData(cartData) {
        const encrypted = EncryptDecryptService.encryptData(cartData);
        localStorage.setItem('cart', encrypted);
    }

    static getCartData() {
        const encryptedCart = localStorage.getItem('cart');
        if (encryptedCart) {
            return EncryptDecryptService.decryptData(encryptedCart);
        }
        return null;
    }

    static saveToken(token: string) {
        const decoded: any = jwtDecode(token);
        localStorage.setItem('token', EncryptDecryptService.encryptData(token));
        localStorage.setItem('guestId', EncryptDecryptService.encryptData(decoded._id));
        localStorage.setItem('name', EncryptDecryptService.encryptData(decoded.name));
        localStorage.setItem('email', EncryptDecryptService.encryptData(decoded.email));
        localStorage.setItem('contactNumber', EncryptDecryptService.encryptData(decoded.contactNumber));
    }

    static getToken() {
        return localStorage.getItem('token') ? EncryptDecryptService.decryptData(localStorage.getItem('token')) : null;
    }

    static getName() {
        return localStorage.getItem('name') ? EncryptDecryptService.decryptData(localStorage.getItem('name')) : null;
    }

    static getEmail() {
        return localStorage.getItem('email') ? EncryptDecryptService.decryptData(localStorage.getItem('email')) : null;
    }

    static getContactNumber() {
        return localStorage.getItem('contactNumber') ? EncryptDecryptService.decryptData(localStorage.getItem('contactNumber')) : null;
    }

    static getGuestId() {
        return localStorage.getItem('guestId') ? EncryptDecryptService.decryptData(localStorage.getItem('guestId')) : null;
    }

    static logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('contactNumber');
        localStorage.removeItem('guestId');
    }
}