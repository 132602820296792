import CryptoJS from 'crypto-js';

export class EncryptDecryptService {
    static encryptData(data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.NEXT_PUBLIC_ENCRYPTION_SECRET_KEY).toString();
    }

    static decryptData(encryptedData) {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.NEXT_PUBLIC_ENCRYPTION_SECRET_KEY);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            // Handle decryption error
            localStorage.clear();
            window.location.reload();
        }
    }
}